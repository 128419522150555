#TriangleLoading {
  // width: 100%;
  // height: 100vh;
  // background: teal;
  position: absolute;
}



// #svg_logo {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate( -50%, -50% );
// }


// #svg_logo path:nth-child( 1 ) {
//   stroke-dasharray: 490;
//   stroke-dashoffset: 490;
//   animation: line-anim 2s ease forwards;
  
// }
// #svg_logo path:nth-child( 2 ) {
//   stroke-dasharray: 610;
//   stroke-dashoffset: 610;
//   animation: line-anim 2s ease forwards;
  
// }
// #svg_logo path:nth-child( 3 ) {
//   stroke-dasharray: 508;
//   stroke-dashoffset: 508;
//   animation: line-anim 2s ease forwards;
  
// }



// // Setting 'storke-dashoffset: 0;' gives us the full framing of the svg.
// @keyframes line-anim {
//   to {
//     stroke-dashoffset: 0;
//   }
// }



