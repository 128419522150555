.worksEducationPreview__wrapper {
  width: 100%;
  height: 100%;
  position: relative;

  /*----------------------  ImgSlider.js  */
  .imgFadeSlider__wrapper {
    width: 100vw;
    height: 100vh;
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    .imgFadeSlider {
      width: 100vw;
      height: 100vh;
      background-size: cover;
      background-position: bottom;
      position: absolute;
      will-change: opacity;
      z-index: 0;
    }
  }

  /*----------------------  works-edu-preview.component.js  */
  .worksEducationPreview {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-width: 1280px;
    position: absolute;

    left: 0;
    right: 0;

    .track {
      width: 1px;
      height: 100%;
      background: silver;
      margin: auto;
      position: absolute;
      z-index: 1;
      left: 125px;
    }

    .trackProgressBar__wrapper {
      width: 50px;
      height: 100%;
      margin: auto;
      max-height: 698px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 100px;
      z-index: 1;


      /*-----------------  PaginationBtns.js  */
      .pagBtns__wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        position: absolute;
        left: -60px;
        
        .targetYear {
          color: white;
          font-family: 'Montserrat', sans-serif !important;
          letter-spacing: 1px;
          transition: 0.3s;
          -webkit-font-smoothing: antialiased;

          &:hover {
            color: #a3734c;
            cursor: pointer;
          }
        }
      }

      .progressBar__wrapper {
        width: 100%;
        // height: calc(100% - 110px);
        height: calc(100% - 97px);
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;

        .progressBar {
          width: 5px;
          height: 30px;
          background: white;
          border-radius: 10px;
          margin: auto;
          position: absolute;
          transition: 1s;
          right: 0;
          left: 0;
        }

        .highlightBar {
          width: 125px;
          height: 30px;
          background: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 3px;
          opacity: 0.2;
          position: relative;


          left: -100px;
        }
      }
    }


    /*-----------  works-edu-content.component.jsx  */
    .worksEduContent__wrapper {
      width: 450px;
      height: fit-content;
      align-items: center;
      display: flex;
      font-weight: 300;
      margin: auto;
      position: absolute;
      z-index: 1;

      top: 0;
      bottom: 0;
      left: 200px;
      -webkit-font-smoothing: antialiased;

      .worksEduContent {

        .schoolOrCompany {

          h1 {
            color: white;
            font-family: 'Sulphur Point', sans-serif !important;
            font-size: 40px;
            letter-spacing: 2px;
            position: relative;
          }
          h3 {
            color: white;
            font-family: 'Montserrat', sans-serif !important;
            font-size: 20px;
            font-weight: 400;
            position: relative;
          }

          span {
            width: 100px;
            height: 1px;
            background: white;
            background: linear-gradient(90deg,#ff1493 0,coral);
            content: '';
            display: block;
            margin: 25px 0;
            position: relative;
          }
        }
        .focus {
          h3, h4 {
            color: white;
            font-family: 'Montserrat', sans-serif !important;
            font-size: 20px;
            font-weight: 400;
            position: relative;
          }
          h4 {
            font-size: 16px;
          }
        }
        .schoolOrCompanyIntro {

          p {
            color: white;
            font-size: 15px;
            line-height: 20px;
            margin-bottom: 25px;
            position: relative;
          }
        }
        .goToSite {
          &:hover {
            cursor: pointer;
          }

          p {
            background: rgba(255, 255, 255, 0.1);
            background: none;
            border-left: 1px solid #fff;
            color: white;
            font-family: 'Montserrat', sans-serif !important;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 2px;
            margin: 0;
            padding: 10px 30px;
            text-align: center;
            text-transform: uppercase;
          }
        }
      }
    }


    //  PREV  /  NEXT  BTNS
    .prevNextBtns__wrapper {
      width: 100px;
      height: 75px;
      display: flex;
      flex-direction: column;
      margin: auto;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;

      .trigger_prevSlide,
      .trigger_nextSlide {
        width: auto;
        height: 20px;
        color: white;
        display: flex;
        letter-spacing: 3px;
        position: relative;
        transition: 0.3s;
        z-index: 1;

        &:hover {
          color: #a3734c;
          cursor: pointer;
        }
      }

      .trigger_prevSlide {
        border-bottom: 1px solid silver;
        padding-bottom: 35px;
      }
      .trigger_nextSlide {
        padding-top: 17px;
      }
    }
  }
}




