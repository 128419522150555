.fixedEmailMeBtn__wrapper {
	.animate_right {
		// border: 1px solid red;
    width: 75px;
    height: 25px;
    margin: auto;
    overflow: hidden;
    position: fixed;
    transition: 0.3s;
    z-index: 9999;
    top: 20px;
    left: 0;

    &::after {
	    width: 35px;
	    height: 1px;
	    background: gray;
	    content: '';
	    display: block;
	    position: absolute;
	    top: 11px;
    }

		.EmailMe {
	    /* border: 1px solid red; */
	    width: 23px;
	    height: 23px;
	    align-items: center;
	    display: flex;
	    cursor: pointer;
	    justify-content: center;
	    margin-left: 0;
	    position: absolute;
	    top: 0;
	    left: 43px;
		}
	}
}


@media screen and ( max-width: 550px ) {
	.fixedEmailMeBtn__wrapper {
		.animate_right {
			.EmailMe { left: 23px; }
			&::after { width: 15px; }
		}
	}
}


// iPhone X  -  375 x 812
@media screen and ( max-width: 375px ) {
	.fixedEmailMeBtn__wrapper {
		.animate_right {
			.EmailMe { left: 18px; }
			&::after { width: 10px; }
		}
	}
}











