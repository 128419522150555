body {
  font-family: 'Raleway', sans-serif;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
}


a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}


@import url('https://fonts.googleapis.com/css?family=Raleway|Pathway+Gothic+One|Nanum+Myeongjo:400,700,800|Major+Mono+Display|Sulphur+Point|Kanit:700,900|Montserrat+Alternates:700|Montserrat:300,400,500,600,700&display=swap');
.className { 
	font-family: 'Nanum Myeongjo', sans-serif !important;
	font-family: 'Pathway Gothic One', sans-serif;
	font-family: 'Montserrat', sans-serif !important;
	font-family: 'Sulphur Point', sans-serif !important;
	font-family: 'Kanit', sans-serif;
	font-family: 'Montserrat Alternates', sans-serif;
	font-family: 'Major Mono Display', monospace;
}










