/*  N O T E  :  D e l a y i n g   A n i m a t i o n s

  On component mount, delays set on component :;
    - <TransitionRight delayOnMount={ 1200 }>

  On component exit '.page-exit' :
    - transition-delay: 1s;  ----->  WORKS HERE


  E X A M P L E  :
  - this does not work targeting individual components separately.

  .About__Container.page-enter { ... }
  .About__Container.page-enter-active { ... }
  .About__Container.page-enter-done { ... }
*/



//  -----------------------  A B O U T  :  P A G E
 .page-enter .About__Container { 
  // opacity: 0;
  // transition: all 3s ease-in;
  // transition-delay: 4s;
}
.page-enter-active .About__Container { 
  // opacity: 1;
  // transition: all 3s ease-in;
  // transition-delay: 3s;
}
// .page-enter-done {
//   opacity: 1;
//   transition: all 3s ease-in;
// }
.page-exit .About__Container { 
  // opacity: 0;
  // transition: all 0.3s ease-in;
  // transition-delay: 2.5s;
}



//  ----------------------------  I N T R O  :  S E C T I O N
.page-exit { 
  .IntroPage__Wrapper {
    &::before,
    &::after {
      opacity: 0;
    }
  }
}



//  --------------------------------  A B O U T  :  I N T R O
.page-exit .About__Container {
  opacity: 0;
  transition: all 0.3s ease-in;
  transition-delay: 1s;

  // ---------------------  UNCOMMENT - to transition each element individually
  /*
  .silverLine { opacity: 0; }

  .AboutIntro__Wrapper {
    .bkgdPageTitle {
      opacity: 0;
      transition: all 0.3s ease-in;
    }
    .AboutIntroContent__Wrapper {
      .fadeSliderAndBtns__wrapper {
        opacity: 0;
        transform: translate( 0px, -100px );
        transition: all 0.3s ease-in;
        // transition-delay: 0.5s;
        transition-delay: 1.3s;
        // transition-timing-function: cubic-bezier(.43,-0.01,1,.66);
      }
    }    
  }

  //  Targeting  >  AboutMe__Title
  .AboutMe__Title {
    opacity: 0;
    transform: translate( 0px, -100px );
    transition: all 0.3s ease-in;
    // transition-delay: 0.9s;
    transition-delay: 1.3s;
    // transition-timing-function: cubic-bezier(.43,-0.01,1,.66);  
  }
  .aboutIntroContent {
    opacity: 0;
    transform: translate( 0px, -100px );
    transition: all 0.3s ease-in;
    // transition-delay: 1.1s;
    transition-delay: 1.3s;
    // transition-timing-function: cubic-bezier(.43,-0.01,1,.66);  
  }
  */



  //  schoolOrCompany, focus, schoolOrCompanyIntro
  .AboutWorks__Wrapper {
    .trackProgressBar__wrapper {

      // Loop from 1-12.
      @for $i from 1 through 1000 {
        .targetYear {
          &:nth-child(#{$i}) {
            // Delay the animation. Delay increases as items loop.
            animation-delay: $i * (0.4s) !important;

            opacity: 0;
            transform: translate( -150px, 0 );
            transition: all 0.5s ease-in;
            transition-timing-function: cubic-bezier(.43,-0.01,1,.66); 
          }
        }
      }
    }

    // ---------------------  UNCOMMENT - to transition each element individually
    /*
    .worksEduContent__wrapper {
      .schoolOrCompany,
      .focus,
      .schoolOrCompanyIntro {
        opacity: 0;
        transform: translate( 0%, -200px );
        transition: all 0.5s ease-in;
        transition-timing-function: cubic-bezier(.43,-0.01,1,.66);
      }

      .schoolOrCompany { 
        // transition-delay: 1.2s;
        transition-delay: 1.3s;
      }
      .focus { 
        // transition-delay: 1.4s;
        transition-delay: 1.3s;
      }
      .schoolOrCompanyIntro { 
        // transition-delay: 1.6s;
        transition-delay: 1.3s;
      }
    }
    */

  }

  // ---------------------  UNCOMMENT - to transition each element individually
  /*
  .AboutSkills__Wrapper {
    .skillsComponent__wrapper {
      h2 {
        opacity: 0;
        transform: translate( 0, -150px );
        transition: all 0.5s ease-in;
        transition-timing-function: cubic-bezier(.43,-0.01,1,.66); 
        // transition-delay: 1.5s;
        transition-delay: 1.3s;
      }
      ul {
        li {
          opacity: 0;
          transform: translate( 0, -150px );
          transition: all 0.5s ease-in;
          transition-timing-function: cubic-bezier(.43,-0.01,1,.66); 
          // transition-delay: 1.7s;
          transition-delay: 1.3s;

          h4 {}
        }
      }
    }
  }


  .AboutSocial__Wrapper {
    .animate_up {
      a, span {
        opacity: 0;
        transition: all 0.5s ease-in;
        // transition-delay: 0.5s;
        transition-delay: 1.3s;
      }
    }
  }


  //  AboutFooter__Wrapper
  .AboutFooter__Wrapper {
    .contactBar h4,
    .contactBar span,
    .social,
    .footerNavLinks,
    .linkedin__container__mountExit,
    h2 {
      opacity: 0;
      transform: translate( 0, -150px );
      transition: all 0.5s ease-in;
      transition-timing-function: cubic-bezier(.43,-0.01,1,.66); 
    }
    .linkedin__container {
      opacity: 0;
      transform: translate( -150px, 0 );
      transition: all 0.5s ease-in;
      transition-timing-function: cubic-bezier(.43,-0.01,1,.66); 
    }

    .contactBar h4 {                  
      // transition-delay: 0.3s; 
      transition-delay: 1.3s;
    }
    .contactBar span {                
      // transition-delay: 0.5s; 
      transition-delay: 1.3s;
    }
    .social {                         
      // transition-delay: 0.7s; 
      transition-delay: 1.3s;
    }
    .footerNavLinks {                 
      // transition-delay: 0.9s; 
      transition-delay: 1.3s;
    }
    h2 {                              
      // transition-delay: 1.1s; 
      transition-delay: 1.3s;
    }
    .linkedin__container__mountExit { 
      // transition-delay: 1.3s; 
      transition-delay: 1.3s;
    }
    .linkedin__container {            
      // transition-delay: 0.5s; 
      transition-delay: 1.3s;
    }
  }
  */

}


// @-moz-keyframes loading {
//   0% { transform: translate(0, 100vh); }
//   15% { transform: translate(0, 100vh); }
//   50% { transform: translate(0, 0); }
//   75% { transform: translate(0, -100vh); }
//   100% { transform: translate(0, -100vh); }
// }
// @-webkit-keyframes loading {
//   0% { transform: translate(0, 100vh); }
//   15% { transform: translate(0, 100vh); }
//   50% { transform: translate(0, 0); }
//   75% { transform: translate(0, -100vh); }
//   100% { transform: translate(0, -100vh); }
// }
// @-o-keyframes loading {
//   0% { transform: translate(0, 100vh); }
//   15% { transform: translate(0, 100vh); }
//   50% { transform: translate(0, 0); }
//   75% { transform: translate(0, -100vh); }
//   100% { transform: translate(0, -100vh); }
// }
// @keyframes loading {
//   0% { transform: translate(0, 100vh); }
//   15% { transform: translate(0, 100vh); }
//   50% { transform: translate(0, 0); }
//   75% { transform: translate(0, -100vh); }
//   100% { transform: translate(0, -100vh); }
// }





