
.IntroPage__Wrapper {
  // border: 1px solid red;
  width: 100%;
  // height: 100px;
  height: 300vh;
  display: flex;
  flex-direction: column;
  // font-family: 'Maven Pro', sans-serif;
  // font-family: 'Pathway Gothic One', sans-serif !important;
  // font-family: 'Sulphur Point', sans-serif !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 45px;
  font-weight: 100;
  // justify-content: center;
  // margin: 23% auto auto;
  margin: auto;
  position: relative;
  text-align: center;
  text-transform: uppercase;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1;

 // -webkit-animation: flickerAnimation 4.5s;
 // -moz-animation: flickerAnimation 4.5s;
 // -o-animation: flickerAnimation 4.5s;
  // animation: flickerAnimation 4.5s;


  &::before,
  &::after {
    width: 1px;
    height: 35%;
    background: silver;
    content: '';
    display: block;
    margin: auto;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &::before {
    background-image: linear-gradient(white, black);
    top: -20%;
  }
  &::after {
    background-image: linear-gradient(black, white);
    top: 50%;
  }


  .ahwd__wrapper {
    // width: 100vw;
    width: 100%;
    // height: 100vh;
    height: 100px;
    margin: auto;
    position: relative;
    top: -33.333333%;



    .ah__wrapper {

      span.letter:first-of-type {
        font-family: 'Major Mono Display', monospace;
        font-weight: bold;
        font-size: 60px;
        position: relative;
        bottom: 1px;
      }
      span.letter {
        font-weight: 500;
        letter-spacing: 12px;
      }
    }
    .wd__wrapper {
      font-size: 26px;

      span.letter {
        font-weight: 400;
        letter-spacing: 10px;
      }
    }
  }


}




//  13 child elements 
// .letter:nth-child(13) {
//     -webkit-animation: fade 3s infinite 2.6s;
//     animation: fade 3s infinite 2.6s;
// }

// Loop from 1-13 :;
@for $i from 1 through 13 {
  .letter {
    &:nth-child(#{$i}) {
      // Delay the animation. Delay increases as items loop.
      // animation-delay: $i * (0.2s) !important;

      //  Occur every 3s
      animation: fade 3s 2.5 $i * (0.2s);
    }
  }
}






//  Mac
@media screen and ( max-width: 1280px ) {

}


// iPhone X  -  1125 x 2436
@media screen and ( max-width: 1125px ) {

}


// iPhone 8 Plus  -  1080 x 1920
@media screen and ( max-width: 1080px ) {
  
}


@media screen and ( max-width: 1000px ) {

}


@media screen and ( max-width: 900px ) {

}


// iPhone 8  -  750 x 1334
@media screen and ( max-width: 750px ) {

}


@media screen and ( max-width: 650px ) {
  .IntroPage__Wrapper {
    font-size: 25px;
  
    .ahwd__wrapper {
      .ah__wrapper {
        span.letter:first-of-type {
          font-size: 40px;
        }
        span.letter {
          letter-spacing: 5px;
        }
      }
      .wd__wrapper {
        font-size: 16px;

        span.letter {
          letter-spacing: 4px;
        }
      }
    }

  }
}


@media screen and ( max-width: 414px ) {

}




















// @keyframes flickerAnimation {
//   0%   { 
//     color: #000;
//     opacity: 0;
//   }
//   50%  { 
//     color: #fff;
//     opacity: 1;
//   }
//   100% { 
//     color: #000;
//     opacity: 0;
//   }
// }





// .IntroPage__Container {
//   border: 1px solid red;
//   width: 100%;
//   height: 50%;
//   background: linear-gradient(to right, #fff 0%, #000 50%, #fff 100%, #fff 200%);
//   background-size: 200%;
//   background-position: 0% 0%;
//   background-clip: text;
//   color: #000;
//   display: flex;
//   font-family: 'Montserrat Alternates', sans-serif;
//   font-family: 'Maven Pro', sans-serif;
//   font-size: 120px;
//   font-weight: bold;
//   padding: 50px;
//   position: fixed;
//   align-items: center;
//   justify-content: center;
//   margin: auto;
//   text-align: center;
//   text-fill-color: transparent;
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   animation: shine 4s reverse;
// }
// @keyframes shine {
//   to {
//     background-position: 200% center;
//   }
// }


