
.About__Container { 
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1 !important;

  .animate_delay {
    // padding: 0 75px;
  }

  .AboutIntro__Wrapper {
    width: calc(100% - 120px);
    height: 150vh;
    margin: 0 auto 50px;
    max-width: 1160px;
    position: relative;

    .AboutIntroContent__Wrapper {
      width: 100%;
      height: 90%;
      font-weight: 400;
      position: absolute;
      text-align: left;
      right: 0;

      display: flex;
      align-items: flex-end;
      flex-direction: column;

      .fadeSliderAndBtns__wrapper {
        width: 100%;
        width: 60%;
        height: 500px;
        overflow: visible;
        padding: 15px;
        position: relative;

        > .animate_up {
          width: 100%;
          height: 100%;
          background: white;
          box-shadow: 2px 2px 10px 0px;
          position: inherit;

          h6 {
            color: gray;
            font-family: 'Sulphur Point', sans-serif !important;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 8px;
            line-height: 15px;
            position: absolute;
            text-transform: uppercase;
            z-index: 1;

            top: 10px;
            left: 15px;
            -webkit-font-smoothing: antialiased;
          }
        }

        .aboutIntroSlider {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
          will-change: opacity;
          display: flex;
          position: absolute;

          top: 0;
          right: 0;
        }

        .AboutIntroBtn__Wrapper {
          width: 130px;
          height: 30px;
          display: flex;
          position: absolute;
          z-index: 9999;

          right: 15px;
          bottom: -35px;

          > .animate_up {
            width: 100%;
            box-shadow: none;
          }

          .imgCount {
            width: 40px;
            height: 30px;
            align-items: center;
            color: lightslategray;
            display: flex;
            font-family: 'Sulphur Point', sans-serif !important;
            justify-content: center;
            margin: auto;
          }

          .aboutIntro__prevBtn,
          .aboutIntro__nextBtn {
            width: auto;
            height: 20px;
            align-items: center;
            color: white;
            display: flex;
            justify-content: center;
            letter-spacing: 3px;
            position: relative;
            &:hover {
              cursor: pointer;
            }
          }
          .aboutIntro__prevBtn {
            background: url('../../assets/arrow_prev.svg');

            // &::before {
            //   width: 4px;
            //   height: 100%;
            //   background: white;
            //   content: '';
            //   display: block;
            //   position: absolute;
            //   right: 7px;
            // }
          }
          .aboutIntro__nextBtn {
            background: url('../../assets/arrow_next.svg');
            right: 0;

            // &::before {
            //   width: 4px;
            //   height: 100%;
            //   background: white;
            //   content: '';
            //   display: block;
            //   position: absolute;
            //   left: 7px;
            // }
          }
          .aboutIntro__prevBtn,
          .aboutIntro__nextBtn {
            width: 30px;
            height: 100%;
            background-size: cover;
            position: absolute;
            top: 0;
          }
        }
      }

      // .AboutMe__Title {
      //   display: none;
      // }


      .AboutMe__Title {
        width: 100%;
        height: 150px;
        position: absolute;
        z-index: 1;
        top: 25%;
        left: 15px;

        h6 {
          color: lightslategray;
          font-family: 'Sulphur Point', sans-serif !important;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 1px;
          margin-bottom: 15px;
          text-transform: uppercase;
          -webkit-font-smoothing: antialiased;

          span {
            margin: 0;
          }
        }
        h1 {
          font-family: 'Pathway Gothic One', sans-serif !important;
          font-size: 100px;
          font-weight: 400;
          letter-spacing: 18px;
          line-height: 75px;
          position: relative;
          text-align: left;
          text-transform: uppercase;
        }
      }


      .aboutIntroContent {
        width: 60%;
        position: relative;
        top: 30px;
        right: 0;

        .SectionTitle {
          height: 35px;
          color: gray;
          font-size: 18px;
          letter-spacing: 3px;
          line-height: 15px;
          margin: 0;
          padding: 0;
          position: absolute;
          text-transform: uppercase;
          transform: rotate(-90deg);
          top: 75px;
          left: -15px;
          z-index: 1;

          h6 {
            font-family: 'Sulphur Point', sans-serif !important;
            font-size: 15px;
            font-weight: 500;
            -webkit-font-smoothing: antialiased;
          }
        }
        p:first-of-type {
          margin-top: 40px;
        }
        p {
          font-size: 15px;
          font-weight: 300;
          letter-spacing: 1px;
          line-height: 30px;
          margin: 0;
          padding: 0px 15px 25px 75px;
        }
      }
    }
  }
  // ------  /AboutIntro__Wrapper 
}



// -------------------------  AboutWorks__Wrapper
.AboutWorks__Wrapper::before {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
}
.AboutWorks__Wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .works__Title {}
}



// -------------------------  AboutSkills__Wrapper
.AboutSkills__Wrapper {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  max-width: 1280px;
  position: relative;

  .skillsComponent__wrapper {
    width: 80%;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 100px;

    h2 {
      font-family: 'Montserrat', sans-serif !important;
      font-size: 28px;
      font-weight: 100;
      letter-spacing: 0;
      margin: 0 0 25px;
      -webkit-font-smoothing: antialiased;
    }

    ul {
      line-height: 35px;
      margin: 0;
      padding: 0;

      li {
        display: flex;
        line-height: 150%;
        list-style: none;
        margin-bottom: 15px;


        h4 {
          width: 25%;
          font-family: 'Sulphur Point', sans-serif !important;
          font-weight: 100;
          letter-spacing: 5px;
          text-transform: uppercase;
        }
        p {
          width: 75%;
          font-size: 14px;
          letter-spacing: 1px;
          margin: 0;
        }
      }
    }
  }
}



// -------------------------  AboutWorksStills__Wrapper
.AboutWorksStills__Wrapper {
  width: 100%;
  height: 450px;
  display: flex;

  .worksPreview__container,
  .stillsPreview__container {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;

    &::before {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
    }

    a {
      height: 50px;
      font-family: 'Montserrat', sans-serif !important;
      font-size: 40px;
      letter-spacing: 5px;
      text-transform: uppercase;
      overflow: hidden;
      position: relative;
      z-index: 10;
      -webkit-font-smoothing: antialiased;
    }
    img {
      width: 125%;
      height: auto;
      background-size: cover;
      background-position: center;
      position: absolute;
      z-index: 0;
    }
  }
  .worksPreview__container { width: 50%; }
  .stillsPreview__container { width: 50%; }
}



// -------------------------  AboutSocial__Wrapper
// Linkedin / email
.AboutSocial__Wrapper {
  width: 100%;
  height: 350px;
  background: black;
  background: hsla( 0, 0%, 9%, .98);
  background: #16181d;
  box-shadow: 0px 0px 30px 0px;
  display: flex;
  overflow: hidden;

  align-items: center;
  justify-content: center;

  .animate_up {
    display: flex;
    justify-content: center;
    position: relative;
    right: -20px;

    a:first-of-type {
      display: flex;
      position: relative;
    }
    a:first-of-type::after {
      width: 1px;
      height: 25px;
      background: white;
      content: '';
      display: block;
      position: relative;
      right: 20px;
    }

    a {
      color: white;
      font-family: 'Montserrat', sans-serif !important;
      font-size: 22px;
      font-weight: 100;
      letter-spacing: 4px;
      position: relative;
      -webkit-font-smoothing: antialiased;

      svg {
        width: 25px;
        position: relative;
        right: 40px;
        bottom: 4px;
      }
    }
  }
}



.silverLine {
  width: 1px;
  height: 70%;
  background: silver;
  background-image: linear-gradient(white, black);
  position: absolute;
  bottom: -50px;
  left: 65px;
}

.silverLine_skillsSection {
  width: 1px;
  height: 75%;
  background: lightslategray;
  background-image: linear-gradient(black, white);
  position: absolute;
  top: 0;
  left: 125px;
}



html {
  scroll-behavior: smooth;
}



.pink {
  width: 50%;
  height: 50%;
  border: 15px solid yellow;
  background: linear-gradient(to right, deeppink 0%, coral 100%);
}

.teal {
  width: 50%;
  height: 50%;
  border: 15px solid orange;
  background: linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%);
}

.tomato {
  width: 50%;
  height: 50%;
  border: 15px solid red;
  background: linear-gradient(to right, tomato 0%, gold 100%);
}


@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}








//  Mac
@media screen and ( max-width: 1280px ) {
  //  PREV  /  NEXT  BTNS
  .AboutWorks__Wrapper {
    .prevNextBtns__wrapper {
      width: 100px;
    }
  }


  //  ABOUT - WORKS / STILLS
  .AboutWorksStills__Wrapper {
    .worksPreview__container,
    .stillsPreview__container {
      img {
        width: 800px;
      }  
    }    
  }
}

@media screen and ( max-width: 1000px ) {
  //  ABOUT INTRO
  .About__Container .AboutIntro__Wrapper {
    width: calc(100% - 25px);

    .silverLine { left: 112px; }
    .AboutIntroContent__Wrapper .aboutIntroContent p {
      line-height: 25px;
    }
  }

  //  ABOUT SKILLS
  .silverLine_skillsSection { opacity: 0.2; }
  .AboutSkills__Wrapper {
    .skillsComponent__wrapper {
      width: 100%;
      padding: 50px;
      left: 0;

      ul {
        li {
          h4 {
            letter-spacing: 2px;
            margin-right: 20px;
          }
          p {
            letter-spacing: 1px;
          }
        }
      }
    }
  }

  //  ABOUT - WORKS / STILLS
  .AboutWorksStills__Wrapper {
    height: 375px;
  }
  .AboutWorksStills__Wrapper {
    .worksPreview__container,
    .stillsPreview__container {
      img {
        width: 650px;
      }  
    }
  }
}


@media screen and ( max-width: 900px ) {
  //  ABOUT INTRO
  .About__Container .AboutIntro__Wrapper {
    .AboutIntroContent__Wrapper {
      .fadeSliderAndBtns__wrapper,
      .aboutIntroContent {
        width: 70%;
      }
    }
  }
}


@media screen and ( max-width: 800px ) {
  //  ABOUT INTRO
  .About__Container {
    .AboutIntro__Wrapper {
      height: 160vh;

      .AboutIntroContent__Wrapper {
        .fadeSliderAndBtns__wrapper {
          height: 400px;
        }
        .AboutMe__Title {
          top: 20%;

          h6 {
            margin-bottom: 0;
          }
          h1 {
            font-size: 80px;
            letter-spacing: 10px;
          }
        }
      }
    }
  }

  .AboutSkills__Wrapper {
    .silverLine_skillsSection {
      display: none;
    }
  }

  //  ABOUT WORKS EDU
  .AboutWorks__Wrapper {
    .worksEducationPreview__wrapper {
      .worksEducationPreview {

        .worksEduContent__wrapper {
          width: calc( 90% - 165px );
          left: 165px;

          .worksEduContent {
            .schoolOrCompany {
              h1 { font-size: 33px; }
              h3 { font-size: 18px; }
            }
            .schoolOrCompanyIntro p {
              font-size: 14px;
              line-height: 17px;
            }
          }
        }

        .prevNextBtns__wrapper {
          top: auto;
          right: auto;
          bottom: 0;
          left: 165px;

          .animate_left {
            display: flex;

            .trigger_prevSlide,
            .trigger_nextSlide {
              border-bottom: none;
              padding: 0;
            }
            .trigger_prevSlide {
              border-right: 1px solid silver;
              padding-right: 25px;
            }
            .trigger_nextSlide {
              padding-left: 25px;
            }
          }
        }

      }  //  worksEducationPreview
    }  //  worksEducationPreview__wrapper
  }
}


// iPhone 8  -  750 x 1334
@media screen and ( max-width: 750px ) {
  //  ABOUT SKILLS
  .AboutSkills__Wrapper .skillsComponent__wrapper {
    width: 100%;
    padding: 50px;
    left: 0;

    h2 {
      font-size: 25px;
      font-weight: 400;
    }

    ul {
      li {
        flex-direction: column;
        margin-bottom: 15px;

        h4 {
          font-weight: 600;
          line-height: 20px;
        }
        p {
          width: 100%;
          color: gray;
          line-height: 20px;
          padding-left: 25px;
        }
      }
    }
  }


  .worksEducationPreview {
    .worksEduContent__wrapper {
      .worksEduContent {
        .schoolOrCompany {
          h1 { 
            font-size: 28px;
            letter-spacing: 1px;
          }
        }
      }
    }
  }


  //  ABOUT - WORKS / STILLS
  .AboutWorksStills__Wrapper {
    height: 300px;

    .worksPreview__container,
    .stillsPreview__container {
      a {
        font-size: 30px;
        top: 15px;
      }
      img {
        width: 625px;
      }  
    }
  }

  //  ABOUT - Linkedin / Gmail
  .AboutSocial__Wrapper {
    height: 250px;

    .animate_up {
      a {
        font-size: 20px;
        letter-spacing: 2px;

        svg { width: 23px; }
      }
    }
  }
}


@media screen and ( max-width: 700px ) {
  //  ABOUT INTRO
  .About__Container .AboutIntro__Wrapper {
    height: 155vh;

    .silverLine { display: none; }

    .AboutIntroContent__Wrapper {
      .AboutMe__Title {
        height: 90px;

        h1 { font-size: 62px; }
      }
      .fadeSliderAndBtns__wrapper { height: 350px;}
      .fadeSliderAndBtns__wrapper, 
      .aboutIntroContent { width: 80%; }
      .aboutIntroContent {
        p { padding: 0px 15px 25px 50px; }
      }
    }
  }
}


@media screen and ( max-width: 600px ) {
  //  ABOUT INTRO
  .About__Container .AboutIntro__Wrapper {
    height: 150vh;

    .AboutIntroContent__Wrapper .aboutIntroContent {
      .SectionTitle { top: 70px; }
      p { line-height: 20px; }
    }
  }

  //  ABOUT SKILLS
  .AboutSkills__Wrapper .skillsComponent__wrapper {
    padding: 50px 25px;
  }
}


@media screen and ( max-width: 550px ) {
  //  ABOUT INTRO
  .silverLine { display: none; }
  .About__Container .AboutIntro__Wrapper {
    height: 135vh;
    overflow: hidden;

    .AboutIntroContent__Wrapper {
      .AboutMe__Title {
        h6 {
          font-size: 14px;
          letter-spacing: 0;
        }
        h1 {
          font-size: 45px;
          line-height: 45px;
        }
      }

      .fadeSliderAndBtns__wrapper { height: 300px; }
      .aboutIntroContent { width: 100%; }
    }
  }

  .AboutWorks__Wrapper {
    .worksEducationPreview__wrapper {
      .worksEducationPreview {
        .track { left: 70px; }
        .trackProgressBar__wrapper {
          left: 45px;

          .pagBtns__wrapper {
            left: -34px;

            .targetYear {
              font-size: 12px;
              text-align: center;
            }
          }
        }

        .worksEduContent__wrapper {
          width: calc( 100% - 135px);
          top: 0;
          bottom: 0;
          left: 110px;

          .worksEduContent {
            .schoolOrCompany {
              h1 { font-size: 28px; }
              h3 { font-size: 15px; }
            }
            .focus {
              h4 { font-size: 14px; }
            }
            .schoolOrCompanyIntro {
              .animate_up {
                p {
                  font-size: 13px;
                  line-height: 15px;
                }
              }
            }
          }
        }

        .prevNextBtns__wrapper {
          width: 155px;
          right: 0;
          left: 0;
        }
      }
    }
  }


  //  ABOUT SKILLS
  .AboutSkills__Wrapper { height: 115vh; }
  .silverLine_skillsSection { display: none; }


  //  ABOUT - WORKS / STILLS
  .AboutWorksStills__Wrapper { height: 250px; }
  .AboutWorksStills__Wrapper {
    .worksPreview__container,
    .stillsPreview__container {
      a {
        font-size: 20px;
        top: 20px;
      }
      img { width: 455px; }
    }
  }
}



@media screen and ( max-width: 450px ) {
  .About__Container {
    .AboutIntro__Wrapper {
      height: 145vh;

      .AboutIntroContent__Wrapper {
        .fadeSliderAndBtns__wrapper {
          width: 90%;
          height: 250px;
        }

        .AboutMe__Title {
          top: 25%;
        }

        .aboutIntroContent {
          width: 90%;
          top: 10%;
        }
      }
    }
    .AboutSkills__Wrapper {
        height: 115vh;
    }
  }

  .AboutWorks__Wrapper {
    .worksEducationPreview__wrapper {
      .worksEducationPreview {
        .worksEduContent__wrapper {
          .worksEduContent {
            .schoolOrCompany {
              h1 {
                font-size: 28px;
                line-height: 115%;
                margin-bottom: 10px;
              }
            }

            .schoolOrCompanyIntro .animate_up {
              p {
                font-size: 15px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }  
  }

  .AboutSkills__Wrapper {
    .skillsComponent__wrapper {
      ul {
        li {
          h4 {
            width: 50%;
            margin-bottom: 5px;
          }
          p {

          }
        }
      }
    }
  }

  //  ABOUT - Linkedin / Gmail
  .AboutSocial__Wrapper {
    height: 200px;

    .animate_up {
      a {
      font-size: 16px;
      letter-spacing: 0px;
      
      svg { width: 21px; }
      }
    }
  }
}


@media screen and ( max-width: 430px ) {
  .About__Container {
    .AboutIntro__Wrapper {
      height: 145vh;

      .AboutIntroContent__Wrapper {
        .fadeSliderAndBtns__wrapper {
          width: 90%;
        }
        .AboutMe__Title {
          top: 29%;
        }
      }
    }
  }
}


// @media screen and ( max-width: 428px ) {
//   .About__Container {
//     .AboutIntro__Wrapper {
//       height: 145vh;
//     }
//   }
// }


@media screen and ( max-width: 414px ) {
  //  ABOUT INTRO
  .About__Container .AboutIntro__Wrapper {
    width: calc(100% - 10px);
    height: 125vh;
    overflow: hidden;

    .AboutIntroContent__Wrapper {
      .fadeSliderAndBtns__wrapper {
        width: 85%;
        height: 235px;
      }

      .AboutMe__Title {
        padding-right: 20px;
        top: 30%;

        h6 {
          line-height: 110%;
        }
      }

      .aboutIntroContent {
        top: 13%;

        p { 
          line-height: 18px; 
        }
      }
    }
  }

  .worksEducationPreview .worksEduContent__wrapper {
    top: 80px;
    bottom: auto;
    left: 95px;


    .worksEduContent .schoolOrCompany {
      h1 { font-size: 22px; }
      h3 { font-size: 16px; }
    }
  }

  .worksEducationPreview .prevNextBtns__wrapper {
    width: 140px;
    right: 0;
    left: 0;
  }

  .worksEducationPreview .prevNextBtns__wrapper .animate_left {
    .trigger_prevSlide, 
    .trigger_nextSlide {
      font-size: 13px;
    }    
  } 


  //  ABOUT SKILLS
  .AboutSkills__Wrapper {
    height: 110vh;

    .skillsComponent__wrapper {
      h2 {
        font-size: 23px;
        line-height: 25px;
      }
      ul li p {
        line-height: 17px;
      }
    }
  }

  //  ABOUT - WORKS / STILLS
  .AboutWorksStills__Wrapper { height: 200px; }
}


@media screen and ( max-width: 390px ) {
  .About__Container .AboutIntro__Wrapper {
    .AboutIntroContent__Wrapper {
      .AboutMe__Title {
        top: 27%;
      }

      .fadeSliderAndBtns__wrapper > .animate_up {
          height: 115%;
      }
      .fadeSliderAndBtns__wrapper {
        .AboutIntroBtn__Wrapper {
          bottom: -45px;
        }
      }
    }
  }
}



// iPhone X  -  375 x 812
@media screen and ( max-width: 375px ) {
  //  ABOUT INTRO
  .About__Container {
    .AboutIntro__Wrapper {
      .AboutIntroContent__Wrapper {
        bottom: -20px;

        .AboutMe__Title {
          top: 34%;
        }

        .AboutMe__Title h1 {
          font-size: 40px;
          letter-spacing: 7px;
          line-height: 45px;
        }

        .aboutIntroContent {
          p { 
            line-height: 17px; 
          }
        }

        .fadeSliderAndBtns__wrapper > .animate_up {
          height: 180px;
        }
        .fadeSliderAndBtns__wrapper {
          .AboutIntroBtn__Wrapper {
            bottom: -25px;
          }
        }
      }
    }    
  }


  //  ABOUT SKILLS
  .AboutSkills__Wrapper {
    height: 140vh;

    .skillsComponent__wrapper {
      h2 {
        font-size: 21px;
      }
    }
  }


  .worksEducationPreview .worksEduContent__wrapper {
    width: calc( 100% - 91px);
    left: 75px;
  }

  .worksEducationPreview {
    .track {
      left: 56px;
    }
    .trackProgressBar__wrapper {
      left: 31px;

      .pagBtns__wrapper {
        left: -29px;
      }
    }
  }



  //  ABOUT - WORKS / STILLS
  .AboutWorksStills__Wrapper {
    .worksPreview__container,
    .stillsPreview__container {
      a { letter-spacing: 2px; }
      img { width: 350px; }
    }
  }
}




