.globalHomeBtn {
  width: auto;
  height: auto;
  background: none;
  position: fixed;
  z-index: 9999;
  bottom: 35px;
  left: 50px;

	.texts__container {
		.text {
			&:first-of-type {
				font-family: 'Major Mono Display', monospace !important;
				font-weight: bold;
				font-size: 21px;
				position: relative;
				bottom: 0;
			}

			color: #a3734c;
			font-family: 'Montserrat', sans-serif !important;
			font-size: 15px;
			font-weight: 500;
			letter-spacing: 1px;
			// text-shadow: 0px 0px 7px #ffffff;
			text-transform: uppercase;
		}		
	}
}


@media screen and ( max-width: 750px ) {
	.globalHomeBtn {
	  left: 25px;
	}
}