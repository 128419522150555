// -------------------------  aboutFooter__wrapper
.AboutFooter__Wrapper {
  width: 100%;
  height: 600px;
  background: #f2f2f2;
  position: relative;
  z-index: 9999;

  .contactBar {
    width: 100%;
    height: 150px;
    height: 200px;
    align-items: center;
    background: white;
    // box-shadow: 0px 0px 30px 0px;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    position: relative;

    h4 {
      color: #16181d;
      font-family: 'Montserrat', sans-serif !important;
      font-weight: 400;
      letter-spacing: 4px;
    }

    span {
      width: 1px;
      height: 75px;
      background: #16181d;
      content: '';
      display: block;
      margin: auto;
      position: absolute;
      right: 0;
      bottom: -115px;
      left: 0;
    }
  }


  .social {
    display: flex;
    justify-content: center;
    position: relative;
    top: 80px;

    a {
      svg {
        width: 30px;
        height: 30px;
        margin: 0 20px;
      }
    }
  }


  .footerNavLinks {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: auto;
    padding: 140px 0 50px;

    a {
      span {
        height: 20px;
        color: #16181d;
        font-family: 'Montserrat', sans-serif !important;
        letter-spacing: 2px;
        text-transform: lowercase;

        &::after {
          height: 2px;
          background: black;
        }
      }
      &::after {
        content: "\25CF";
        color: #16181d;
        font-size: 8px;
        line-height: 7px;
        margin: 0 30px;
        position: relative;
        top: -11px;
      }
      &:last-of-type {
        &::after { display: none; }
      }
    }
  }
  h2 {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 28px;
    font-weight: 100;
    letter-spacing: 7px;
    text-align: center;
    -webkit-font-smoothing: antialiased;
  }


  .linkedin__container {
    width: 300px;
    height: 25px;
    display: flex;
    position: absolute;
    transform: rotate(-90deg);
    bottom: 175px;
    left: -110px;

    .linkedin__container__mountExit {
      display: flex;

      div:first-of-type {
        width: 25px;
      }

      span {
        width: 2px;
        height: 14px;
        background: #16181d;
        margin: 0 13px 0 7px;
        position: relative;
        top: 4px;
      }

      div {
        height: 25px;

        svg {
          width: 20px;
          height: 17px;
          position: relative;
          bottom: -1px;
          z-index: 1;
        }
        a {
          color: darkslategrey;
          color: silver;
          font-family: 'Sulphur Point', sans-serif !important;
          font-size: 15px;
          letter-spacing: 2px;
          line-height: 24px;
          text-transform: uppercase;
        }
      }      
    }
  }
}



@media screen and ( max-width: 550px ) {
  .AboutFooter__Wrapper {
    h2 {
      font-size: 23px;
      letter-spacing: 4px;
    }

    .footerNavLinks {
      a {
        &::after {
          margin: 0 15px;
        }
      }
    }

    .linkedin__container {
      left: -125px;
    }
  }
}

@media screen and ( max-width: 414px ) {
  .AboutFooter__Wrapper {
    .contactBar { height: 150px; }

    .footerNavLinks {
      a {
        span {
          letter-spacing: 1px;
          font-size: 15px;
        }
      }
    }
  }
}




