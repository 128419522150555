.ContactPage__Wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .contact__header {
    height: 100px !important;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 3px;
    margin: 100px 0 75px;
    padding: 10px 0;
    text-align: center;
    transition: 0.3s;
    z-index: 9999;

    align-items: center;
    justify-content: center;

    top: 90px;

    h5 {
      font-family: 'Montserrat', sans-serif;
      font-size: 17px;
      font-weight: 600;
      letter-spacing: 1px;
      padding: 0;
      position: relative;
      text-transform: uppercase;
      transition: 0.3s;
    }
    h3 {
      height: 50px !important;
      color: #333333;
      font-family: 'Nanum Myeongjo', sans-serif;
      font-size: 30px;
      font-weight: 100;
      justify-content: center;
      letter-spacing: 0px;
      padding: 10px 0;
      text-align: center;
      top: 165px;

      -webkit-font-smoothing: antialiased;
    }
  }



  .ContactImgForm__Wrapper {
    // max-width:1170px;
    // margin-left:auto;
    // margin-right:auto;
    // padding:1em;

    width: 100%;
    height: 700px;
    display: flex;
    margin: auto;
    max-width: 1250px;
    padding: 0 100px 100px;
    position: relative;



    //  LEFT SIDE  -  IMG
    .contactImg__container {
      width: 100%;
      height: 100%;
      margin-right: 25px;
      padding: 0;
      position: relative;
      overflow: hidden;

      img {
        width: auto;
        height: 700px;
        background-size: cover;
        background-position: center;
        position: relative;
      }
    }


    //  RIGHT SIDE  -  CONTACT FORM
    .contactForm__container {
      width: 100%;
      height: 100%;
      margin-left: 25px;
      padding: 0;
      position: relative;

      .contactContent__Wrapper {

        p {
          font-family: 'Montserrat', sans-serif;
          font-size: 13px;
          letter-spacing: 1px;
          margin: 0 0 25px;
          -webkit-font-smoothing: antialiased;
        }
        h6 {
          font-family: 'Montserrat', sans-serif;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 1px;
          margin: 0 0 10px;
          padding: 0;
          position: relative;
          text-transform: uppercase;
          transition: 0.3s;
        }
      }
    }
  }   //  /ContactImgForm__Wrapper







  .WorldMap__Wrapper {
    width: 100%;
    height: 100%;
    // height: 800px !important;
    background: #1b1b1b;
    display: flex;
    overflow: hidden;
    position: relative;
    bottom: 0;

    &:before {
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      position: absolute;
      background-image: linear-gradient(to bottom, #1b1b1b 5%, transparent 55%);
      top: 0;
      z-index: 1;
    }
    &:after {
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      position: absolute;
      background-image: linear-gradient(to bottom, transparent 0, #1b1b1b 95%);
      bottom: 0;
      z-index: 1;
    }

    .animate_up {
      width: 100%;
      height: 100%;
      opacity: unset !important;
      transform: unset !important;

      .worldMap__container {
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;
        bottom: 0;
        overflow: hidden;

        svg {
          // width: 200%;
          // height: 100%;
          // margin: auto;
          // position: relative;
          // top: 40%;
          // left: 25% !important;
          // right: 0;
          // transform: scale(2);

          width: 200%;
          height: inherit;
          margin: auto;
          position: relative;
          top: 40%;
          left: 25% !important;
          right: 0;
          transform: scale(2);
          padding-top: 10%;


        }
        svg#svg2 {
          width: 100%;
          height: 700px;
          display: flex;
        }      
      }

      .info {
        width: 100%;
        position: absolute;
        z-index: 9999;
        top: 0;

        h4 {
          color: #f2f2f2;
          font-family: 'Montserrat', sans-serif !important;
          font-size: 12px;
          font-weight: 100;
          letter-spacing: 1px;
          line-height: 175%;
          position: absolute;
          text-align: right;
          text-transform: uppercase;
          z-index: 1;
          top: 100px;
          right: 50px;

          -webkit-font-smoothing: antialiased;

          a {
            color: #f2f2f2;
          }
        }
      }

      .siteBuiltWith_credits {
        padding: 0;
        position: absolute;
        z-index: 9999;
        bottom: 60px;
        left: 50px;

        .siteBuiltWith,
        .todos {
          margin-bottom: 25px;
        }

        h4, h5,
        a {
          color: #f2f2f2; 
          font-family: 'Montserrat', sans-serif !important;
          font-size: 12px;
          font-weight: 100;
          letter-spacing: 1px;
          -webkit-font-smoothing: antialiased;
        }
        h4 {
          letter-spacing: 3px;
          text-transform: uppercase;

          &::after {
            width: 10px;
            height: 2px;
            background: #f2f2f2;
            content: '';
            display: block;
            margin: 5px 0;
          }
        }

        .credits {
          width: 100%;
          height: 100px;
          position: relative;
        }
      }
    }
  }
}





// 	Mac
@media screen and ( max-width: 1280px ) {

}


// iPhone X  -  1125 x 2436
@media screen and ( max-width: 1125px ) {

}


// iPhone 8 Plus  -  1080 x 1920
@media screen and ( max-width: 1080px ) {
	
}


@media screen and ( max-width: 1000px ) {
	.ContactPage__Wrapper {
		.ContactImgForm__Wrapper {
			height: 600px;
		  flex-direction: column;

			.contactImg__container {
			  display: none;
			}
			.contactForm__container {
				margin: 0;
			}
		}
	}
}


@media screen and ( max-width: 900px ) {
	.ContactPage__Wrapper .WorldMap__Wrapper {
	  height: 500px;

		.worldMap__container svg {
		  padding-top: 0;
		}
	}
}


// iPhone 8  -  750 x 1334
@media screen and ( max-width: 750px ) {
	.ContactPage__Wrapper {
		.contact__header {
			padding: 10px 50px;

			h3 {
			  font-size: 27px;
			}
		}

		.ContactImgForm__Wrapper {
			height: 600px;
			padding: 0 25px 50px;

			.contactForm__container {
			  margin-left: 0;
			}
		}

		.WorldMap__Wrapper {
			.worldMap__container {

			}
			.info h4 {
			  right: 25px;
			  top: 50px;
			}
			.siteBuiltWith_credits {
				left: 25px;
			}
		}

	}
}

@media screen and ( max-width: 450px ) {
  .ContactPage__Wrapper {
    .animate_up {
      .info {
        h4 {
          top: 40px !important;
        }
      }
    }
  }
}

@media screen and ( max-width: 430px ) {
  .ContactPage__Wrapper {
    .animate_up {
      .contactForm__component {
        #contactForm {
          .submitBtn {
            color: #000;

            button {
              color: #000;
            }
          }
        }
      }
      .info {
        h4 {
          line-height: 130%;
          right: 25px !important;
          top: 40px !important;
        }
      }
      .siteBuiltWith_credits {
        left: 25px !important;

        .siteBuiltWith {
          h5 {
            line-height: 140%;
          }
        }
      }
    }
  }
}


@media screen and ( max-width: 414px ) {
	.ContactPage__Wrapper {
		.ContactImgForm__Wrapper {
			height: 650px;
			padding: 0 25px 50px;
		}

		.info h4 {
		  font-size: 10px;
		}

		.siteBuiltWith_credits h4, 
		.siteBuiltWith_credits h5, 
		.siteBuiltWith_credits a {
		  font-size: 10px;
		}

		.WorldMap__Wrapper:before {
			background-image: none;
		}
		.WorldMap__Wrapper:after {
			background-image: none;
		}
	}
}







