.LoaderFillUp__Wrapper {
  width: 100%;
  height: 100%;
  // overflow-y: scroll;
  position: absolute;
  // z-index: 1;

  .loading-text {
    width: 100%;
    // height: 100%;
    color: silver;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 7px;
    line-height: 100vh;
    margin: 0;
    position: fixed;
    text-align: center;
    text-rendering: optimizeLegibility;
    transition: 0.3s;
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // animation: fade 0.3s 1.5s forwards;
    animation: fade 0.3s 2.5s forwards;
    // animation-delay:  1s;
    z-index: 10001;
  }

  .wrapper {
    width: 100%;
    // height: 100%;
    background: transparent;
    display: flex;
    justify-content: center;
    // min-height: 100vh;
    opacity: 1;
    position: absolute;
    z-index: 2;

    top: 0;
    left: 0;

    .tile {
      width: 33.333%;
      color: #000;
      cursor: pointer;
      display: inline-block;
      float: left;
      margin: 0 15px;
      max-width: 300px;
      overflow: hidden;
      position: relative;

      &::hover {
        transform: translate(0, 0);
        transition: transform 0.5s;
      }

      &::before {
        width: 100%;
        height: 100%;
        background: #639;
        content: "PAGE";
        display: block;
        position: absolute;
        z-index: 1;

        animation: loading-two 2.5s 4.5s forwards ease-in-out;
        animation-timing-function: cubic-bezier(0.63, 0, 0.42, 1.01);

        top: 0;
        left: 0;
      }

      .tile-content {
        width: initial;
        height: auto;
        min-height: 135px;
        padding: 15px;
        background: #f2f2f2;
        position: relative;
        animation: fade 0s 5.7s forwards;
        opacity: 0;

        h3 {
          margin: 0;
          margin-bottom: 1em;
          font-size: 1.6em;
          line-height: 1.3em;
          display: inline-block;
          width: auto;
        }
        .date {
          display: inline-block;
          width: auto;
          position: absolute;
          bottom: 15px;
          font-family: 'Raleway', sans-serif;
          font-size: 0.9em;
          color: rgba(0,0,0,0.6);
        }
      }
    }
  }
}


@-moz-keyframes loading {
  0% { transform: translate(0, 100vh); }
  15% { transform: translate(0, 100vh); }
  50% { transform: translate(0, 0); }
  75% { transform: translate(0, -100vh); }
  100% { transform: translate(0, -100vh); }
}
@-webkit-keyframes loading {
  0% { transform: translate(0, 100vh); }
  15% { transform: translate(0, 100vh); }
  50% { transform: translate(0, 0); }
  75% { transform: translate(0, -100vh); }
  100% { transform: translate(0, -100vh); }
}
@-o-keyframes loading {
  0% { transform: translate(0, 100vh); }
  15% { transform: translate(0, 100vh); }
  50% { transform: translate(0, 0); }
  75% { transform: translate(0, -100vh); }
  100% { transform: translate(0, -100vh); }
}
@keyframes loading {
  0% { transform: translate(0, 100vh); }
  15% { transform: translate(0, 100vh); }
  50% { transform: translate(0, 0); }
  75% { transform: translate(0, -100vh); }
  100% { transform: translate(0, -100vh); }
}



@-moz-keyframes loading-two {
  0% { transform: translate(0, 100%); }
  15% { transform: translate(0, 100%); }
  50% { transform: translate(0, 0); }
  75% { transform: translate(0, -100%); }
  100% { transform: translate(0, -100%); }
}
@-webkit-keyframes loading-two {
  0% { transform: translate(0, 100%); }
  15% { transform: translate(0, 100%); }
  50% { transform: translate(0, 0); }
  75% { transform: translate(0, -100%); }
  100% { transform: translate(0, -100%); }
}
@-o-keyframes loading-two {
  0% { transform: translate(0, 100%); }
  15% { transform: translate(0, 100%); }
  50% { transform: translate(0, 0); }
  75% { transform: translate(0, -100%); }
  100% { transform: translate(0, -100%); }
}
@keyframes loading-two {
  0% { transform: translate(0, 100%); }
  15% { transform: translate(0, 100%); }
  50% { transform: translate(0, 0); }
  75% { transform: translate(0, -100%); }
  100% { transform: translate(0, -100%); }
}



// @-moz-keyframes fade {
//   0% { opacity: 0; }
//   100% { opacity: 1; }
// }
// @-webkit-keyframes fade {
//   0% { opacity: 0; }
//   100% { opacity: 1; }
// }
// @-o-keyframes fade {
//   0% { opacity: 0; }
//   100% { opacity: 1; }
// }
@keyframes fade {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { 
    opacity: 0;
    height: 0;
    // overflow: hidden;
  }
}








#TriangleLoading {
  width: 100%;
  height: 100vh;
  // background: teal;
}



#svg_logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate( -50%, -50% );
}


#svg_logo path:nth-child( 1 ) {
  stroke-dasharray: 490;
  stroke-dashoffset: 490;
  animation: line-anim 2s ease forwards;
  
}
#svg_logo path:nth-child( 2 ) {
  stroke-dasharray: 610;
  stroke-dashoffset: 610;
  animation: line-anim 2s ease forwards;
  
}
#svg_logo path:nth-child( 3 ) {
  stroke-dasharray: 508;
  stroke-dashoffset: 508;
  animation: line-anim 2s ease forwards;
  
}



// Setting 'storke-dashoffset: 0;' gives us the full framing of the svg.
@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}



