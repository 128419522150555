/*  N O T E  :  D e l a y i n g   A n i m a t i o n s

  On component mount, delays set on component :;
    - <TransitionRight delayOnMount={ 1200 }>

  On component exit '.page-exit' :
    - transition-delay: 1s;  ----->  WORKS HERE


  E X A M P L E  :
  - this does not work targeting individual components separately.

  .About__Container.page-enter { ... }
  .About__Container.page-enter-active { ... }
  .About__Container.page-enter-done { ... }
*/



// .LinksWrapper_container .animate_right {
//   right: 0;
// }


//  BUTTONS  :  HOME BTN  /  NAV MENU BTNS
.page-exit .hover_scroll_component {
  opacity: 0;
  transition: all 0.3s ease-in;
  transition-delay: 1s;

  // ---------------------  UNCOMMENT - to transition each element individually
  /*
  .homeBtnStyle_container {
    opacity: 0;
    transform: translate( -150px, 0 );
    transition: all 0.5s ease-in;
    // transition-delay: 0.5s;
    transition-delay: 1s;
    transition-timing-function: cubic-bezier(.43,-0.01,1,.66);
  }
  .linksWrapper_container {
    // opacity: 0;
    // transform: translate( -150px, 0 );
    // transition: all 0.7s ease-in;
    // transition-delay: 1s;
    // transition-timing-function: cubic-bezier(.43,-0.01,1,.66);
  }
  */
  .imgs_container {

    .masonryGridComponent {
      .masonry_grid {
        .masonry_grid_column {
          // ---------------------  UNCOMMENT - to transition each element individually
          /*
          opacity: 0;
          transform: translate( -175px, 0 );
          transition: all 0.9s ease-in;
          // transition-delay: 1.5s;
          transition-delay: 1s;
          transition-timing-function: cubic-bezier(.43,-0.01,1,.66);
          */
        }
        // Loop from 1-12.
        @for $i from 1 through 1000 {
          .masonry_grid_column {
            &:nth-child(#{$i}) {
              // Delay the animation. Delay increases as items loop.
              // transition-delay: $i * (0.3s) !important;
              transition-delay: 3s;
            }
          }
        }
      }
    }
  }

}


//  BUTTONS  :  CONTACT EMAIL  /  HIDE MENU  /  SCROLL
.page-exit .hover_scroll_component {
  .showHideBtn,
  .scrollBtn,
  .contactBtnStyles {
    .animate_up {
      .page__inner {
        opacity: 0;
        transform: translate( 0, 150px );
        transition: 0.3s;
        transition-timing-function: cubic-bezier(.43,-0.01,1,.66);
      }
    }   
  }
}


