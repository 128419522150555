/*  N O T E  :  D e l a y i n g   A n i m a t i o n s

  On component mount, delays set on component :;
    - <TransitionRight delayOnMount={ 1200 }>

  On component exit '.page-exit' :
    - transition-delay: 1s;  ----->  WORKS HERE


  E X A M P L E  :
  - this does not work targeting individual components separately.

  .About__Container.page-enter { ... }
  .About__Container.page-enter-active { ... }
  .About__Container.page-enter-done { ... }
*/



//  ----------------------------------  W O R K S  :  P A G E
.page-enter .WorksPage__container { 
  opacity: 0;
  transition: all 3s ease-in;
  // transition-delay: 3s;
}
.page-enter-active .WorksPage__container { 
  opacity: 1;
  transition: all 3s ease-in;
}
// .page-exit .WorksPage__container { 
//   opacity: 0;
//   transition: all 0.3s ease-in;
//   transition-delay: 1.8s;
// }



//  --------------------------------  W O R K S  :  I N T R O
.page-exit .WorksPage__container {
  opacity: 0;
  transition: all 0.3s ease-in;
  transition-delay: 1s;

  // ---------------------  UNCOMMENT - to transition each element individually
  /*
  .Parallax__tag {
    .companyName {
      h3 {
        transform: translate( 0px, -150px );
        transition: all 0.3s ease-in;
        // transition-delay: 1.7s;
        transition-delay: 1.3s;
        transition-timing-function: cubic-bezier(.43,-0.01,1,.66);
      }
    }
    .companyIntro {
      h3 {
        transform: translate( 0px, -150px );
        transition: all 0.3s ease-in;
        // transition-delay: 1.8s;
        transition-delay: 1.3s;
        transition-timing-function: cubic-bezier(.43,-0.01,1,.66);
      }
    }
    .imageUrl {
      img {
        transform: translate( 0px, -150px );
        transition: all 0.4s ease-in;
        // transition-delay: 1.9s;
        transition-delay: 1.3s;
        transition-timing-function: cubic-bezier(.43,-0.01,1,.66);
      }
    }
    .companyDesc {
      p {
        transform: translate( 0%, -150% );
        transition: all 0.4s ease-in;
        // transition-delay: 2s;
        transition-delay: 1.3s;
        transition-timing-function: cubic-bezier(.43,-0.01,1,.66);
      }
    }

    .generalInfo {
      bottom: 100px;
      transition: all 0.3s ease-in;
      // transition-delay: 2.1s;
      transition-delay: 1.3s;
      transition-timing-function: cubic-bezier(.43,-0.01,1,.66);
    }
    .projects__wrapper {
      bottom: 100px;
      transition: all 0.3s ease-in;
      // transition-delay: 2.2s;
      transition-delay: 1.3s;
      transition-timing-function: cubic-bezier(.43,-0.01,1,.66);
    }    
  }
  */

}









