.bkgdPageTitle {
  width: 150%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  opacity: 0.8;
  position: fixed;
  z-index: -1;

  top: 0;
  left: -25%;

  span {
    color: #f2f2f2;
    // color: black;
    // font-family: 'Krona One', sans-serif !important;
    // font-family: 'Kanit', sans-serif !important;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 150px;
    font-weight: bolder;
    margin: 0 50px;
    text-transform: uppercase;
  }
}