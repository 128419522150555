//  ----------------------------------  W O R K S  :  P A G E
.WorksPage__container {
  // border: 2px solid orange;
  width: 100%;
  // height: 1300px !important;
  height: auto !important;
  background: white;
  font-family: sans-serif;
  font-weight: 400;
  padding: 0;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;

  .Parallax__tag > div > div {
    display: flex;
    margin: 0 auto;
  }

  .prevBtn,
  .nextBtn {
    width: 50px;
    height: 100px;
    margin: auto;
    position: fixed;
    z-index: 9999;
    top: 0;
    bottom: 0;

    &:hover { cursor: pointer; }

    svg {
      width: 45px;
      height: 100px;
    }
  }
  .prevBtn { left: 30px; }
  .nextBtn { right: 30px; }



  .Parallax__tag {
    width: 100%;
    height: 1500px !important;
    // background: gray;
    margin: 0 auto;
    // max-width: 700px !important;
    right: 0;
    left: 0;

    .text {
      pointer-events: none;
      justify-content: start !important;
      font-family: 'Kanit', sans-serif;
      line-height: 0px;
      text-transform: uppercase;
    }
    .number {
      font-size: 450px;
      color: #373c4c;

      span {
        display: inline-block;
        position: relative;
        transform: translate3d(-35%, 0, 0);
      }
    }

    .companyName {
      // border: 1px solid red;
      height: 100px !important;
      font-size: 25px;
      font-weight: 600;
      letter-spacing: 3px;
      padding: 10px 0;
      text-align: center;
      align-items: center;
      justify-content: center;
      z-index: 9999;
      top: 90px;
      transition: 0.3s;

      h3 {
        // font-family: 'Krona One', sans-serif;
        // font-family: 'Syncopate', sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-size: 17px;
        font-weight: 600;
        letter-spacing: 1px;
        padding: 0;
        position: relative;
        text-transform: uppercase;
        transition: 0.3s;
      }
    }
    .companyIntro {
      // border: 1px solid blue;
      height: 50px !important;
      font-size: 25px;
      font-weight: 300;
      letter-spacing: 3px;
      justify-content: center;
      padding: 10px 0;
      text-align: center;
      top: 165px;
      
      h3 {
        color: #333333;
        font-family: 'Nanum Myeongjo', sans-serif;
        font-size: 30px;
        font-weight: 100;
        letter-spacing: -2px;
        -webkit-font-smoothing: antialiased;
      }
    }
    .imageUrl {
      top: 265px;
      z-index: 0;

      > div {
        margin: 0 auto;

        img {
          width: 650px;
          height: 375px;
          border-radius: 3px;
          margin: 0 auto;
          max-width: 650px;
          position: relative;
          z-index: 0;

          background-color: black;
          box-shadow: 0 0 10px rgba(0,0,0,1);
          -moz-box-shadow: 0 0 10px rgba(0,0,0,1);
          -webkit-box-shadow: 0 0 10px rgba(0,0,0,1);
          -o-box-shadow: 0 0 10px rgba(0,0,0,1);
        }
      }
    }

    .companyLink {
      border: 2px solid blue;
      width: 100px !important;
      height: 50px !important;
      align-items: center;
      justify-content: center;
    
      a { position: absolute; }
    }
    .companyDesc {
      justify-content: center;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 30px;
      top: 675px;
      padding: 0;
      text-align: left;

      .para {
        width: 100%;
        max-width: 650px;
        position: relative;

        p {
          font-family: 'Raleway', sans-serif;
          font-size: 14px;
          font-weight: 100;
          line-height: 25px;
          position: relative;
        }
      }
    }


    .generalInfo {
      height: 135px !important;
      justify-content: center;
      position: relative;
      bottom: 500px;

      > div {
        width: 100%;
        max-width: 650px;

        .topic {
          width: 100%;
          position: relative;
          margin: 0 auto;
          max-width: 650px;

          h4 {
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
            font-weight: 700;
            letter-spacing: 2px;
            margin: 15px 0;
          }      
        }
        .topicInfo {
          width: 100%;
          height: fit-content;
          padding: 0 0 0 200px;
          position: relative;
          margin: 0 auto;
          max-width: 650px;

          h4, a {
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: 1px;
            margin: 15px 0;
          }
        }
      }
    }


    .projects__wrapper {
      height: 450px !important;
      padding: 50px 0;

      bottom: 0;
      z-index: 9999;

      > div {
        margin: 0 auto;


        > h4 {
          width: 100vw;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          font-family: 'Montserrat Alternates', sans-serif;
          font-family: 'Montserrat', sans-serif;
          font-size: 17px;
          font-weight: 500;
          letter-spacing: 2px;
          margin: 0 auto;
          max-width: 1200px;
          padding: 0 110px 15px;
          text-transform: uppercase;

          // &:after {
          //   width: 100px;
          //   height: 1px;
          //   background: silver;
          //   content: '';
          //   display: block;
          // }
        }

        .projects__container {
          // width: 100%;
          width: 100vw;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin: 0 auto;
          max-width: 1200px;
          // max-width: 1075px;
          // min-width: 1075px;
          padding: 0 100px;

          .project {
            border: 10px solid white;
            width: 33.333333%;
            height: 80px;
            justify-content: center;
            margin: 0;
            overflow: hidden;
            position: relative;
            text-align: center;

            .brand {
              width: 100%;
              height: 100%;
              background: rgba(0,0,0,0.7);
              color: white;
              font-family: 'Montserrat', sans-serif !important;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 1px;
              padding: 0;
              position: relative;
              text-transform: uppercase;
              transition: 0.3s;

              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 9999;
              -webkit-font-smoothing: antialiased;

              &:hover {
                background: rgba(0, 0, 0, 0.5);
                border-left: 3px solid black;
              }
            }
            .brandUrl {}
            .brandImg {
              width: 100%;
              background-position: center;
              background-repeat: no-repeat;
              margin: auto;
              position: absolute;
              top: 0;
              left: 0;
            }
          }

          h2 { margin: 0; }
          h4 { margin: 0;

            .brand {}
          }
        }
      }    
    }

  }
}






//------------------------------  M O B I L E  -  O P T .
// 
// 
//
//  Mac
@media screen and ( max-width: 1280px ) {

}


// iPhone X  -  1125 x 2436
@media screen and ( max-width: 1125px ) {

}


// iPhone 8 Plus  -  1080 x 1920
@media screen and ( max-width: 1080px ) {
  .WorksPage__container {
    .Parallax__tag {
      .projects__wrapper {
        padding: 0;

        > div {
          .projects__container {
            .project {
              width: 50%;
            }
          }
        }
      }
    }  
  }
}


@media screen and ( max-width: 900px ) {
  .WorksPage__container {
    .Parallax__tag {
      .projects__wrapper {
        > div {
          > h4 {
            // border: 1px solid red;
            // width: calc(100% - 115px);
            width: 315px;
            padding: 0px 0px 15px;
            position: absolute;
            left: 11%;

            &::before {
              width: 15px;
              height: 18px;
              border-top: 2px solid black;
              border-left: 2px solid black;
              content: '';
              display: block;
              position: absolute;
              top: 9px;
              left: -23px;
            }
            &::after {
              width: 100px;
              height: 2px;
              background: black;
              content: '';
              display: block;
              position: absolute;
              top: 9px;
              right: 0;
            }
          }
          .projects__container {
            width: calc(80%);
            padding: 37px 0 0;

            .project {}
          }
          .jobDescs__wrapper {
            .jobdesc {
              line-height: 25px;
            }    
          }
        }
      }
    }  
  }
}


// iPad  -  768 x 1024
@media screen and ( max-width: 768px ) {
  .WorksPage__container {
    .Parallax__tag {
      .companyIntro {
        padding: 10px 50px;

        h3 { font-size: 25px; }
      }
      .imageUrl > div img {
        width: 500px;
        height: auto;
      }
      .companyDesc {
        padding: 0 100px;
        top: 625px;
      }
      .generalInfo { padding: 0 100px; }
      .projects__wrapper { padding: 0; }
    }
  }
}


// iPhone 8  -  750 x 1334
@media screen and ( max-width: 750px ) {
  .WorksPage__container {
    .prevBtn { left: 5px; }
    .nextBtn { right: 5px; }

    .Parallax__tag {
      .companyName {
        top: 105px;
      }
      .companyDesc {
        padding: 0 65px;
        top: 605px;
      }
      .generalInfo { bottom: 525px; }
      .projects__wrapper {
        bottom: 25px;

        > div {
          > h4 {

          }
          .projects__container {
            .project {
              border: 5px solid white;
              height: 65px;

              .brand { font-size: 12px; }
            }
          }
          .jobDescs__wrapper {
            .jobdesc {

            }    
          }
        }
      }
    }
  }
}


@media screen and ( max-width: 700px ) {
  .WorksPage__container {

    .Parallax__tag {
      .companyDesc { 
        padding: 0 50px; 
        top: 565px;
      }
      .generalInfo { padding: 0 50px; }
    }
  }

  .projects__wrapper div .projects__container {
    .jobDescs__wrapper {
      .jobdesc {
        line-height: 25px;
      }    
    }
  }
}


@media screen and ( max-width: 600px ) {
  .WorksPage__container {
    .Parallax__tag {
      .companyName {
        
      }
      .companyDesc {

      }
      .generalInfo { 

      }
      .projects__wrapper {
        bottom: 50px;

        > div {
          > h4 {
            padding: 0px 0px 10px;
            left: 8%;
          }
          .projects__container {
            width: calc( 85% );
            padding: 30px 0 0;

            .project {
              .brand { 

              }
            }
          }
          .jobDescs__wrapper {
            .jobdesc {
              line-height: 23px;
            }    
          }
        }
      }
    }
  }
}


@media screen and ( max-width: 550px ) {
  .WorksPage__container {
    .prevBtn { left: 0; }
    .nextBtn { right: 0; }

    .Parallax__tag {
      .companyName {}
      .companyIntro {}
      .imageUrl { 
        top: 285px;

        > div img { width: 400px; }
      }
      .companyDesc { top: 560px; }
      .generalInfo { 
        bottom: 520px; 

        .topicInfo { padding: 0 0 0 125px; }
      }
    }
  }
}


@media screen and ( max-width: 500px ) {
  .WorksPage__container {
    .Parallax__tag {
      .companyName { top: 70px; }
      .companyIntro {
        top: 140px;

        h3 { font-size: 23px; }
      }
      .imageUrl { top: 245px; }
      .companyDesc { top: 495px; }
      .generalInfo { bottom: 550px; }
    }
  }
}


@media screen and ( max-width: 475px ) {
  .WorksPage__container .Parallax__tag {
    .companyDesc {
      .para {
        p { line-height: 19px; }
      }
    }

    .generalInfo {
      > div {
        .topic {
          h4 {
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 15px;
            margin: 9px 0;
          }
        }
      }
    }
    .generalInfo {
      > div {
        .topicInfo {
          font-size: 11px;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 14px;
          margin: 10px 0;
          padding: 0 0 0 85px;

          h4 {
            letter-spacing: 0;
            line-height: 14px;
            margin: 9px 0;
          }
        }
      }
    }
  }
}

@media screen and ( max-width: 430px ) {
  .WorksPage__container {
    .Parallax__tag {
      .companyName {
        .animate_up {
          padding: 0 37px;
        }
      }
      .companyIntro {
        top: 150px;
      }
      .imageUrl > div {
        img {
          width: 350px;
        }
      }
    }
  }
}


// iPhone 6/7/8/Plus  -  414 x 736
@media screen and ( max-width: 414px ) {
  .WorksPage__container {
    .prevBtn { left: 0; }
    .nextBtn { right: 0; }

    .Parallax__tag {
      .companyName {}
      .companyIntro {}
      .imageUrl { 
        top: 235px;

        > div img { width: 325px; }
      }
      .companyDesc { 
        width: 390px;
        top: 435px;
        text-align: justify;
      }
      .generalInfo { bottom: 620px; }
      .projects__wrapper {
        bottom: 125px;

        .projects__container {
          .project {
            .brand {
              font-size: 10px;
              letter-spacing: 0;
            }
          }
        }
      }

    }
  }
}


@media screen and ( max-width: 390px ) {
  .WorksPage__container {
    .Parallax__tag {
      .companyName {
        .animate_up {
          // padding: 0 37px;
        }
      }
      .companyIntro {
        padding: 10px 10px;
      }
      .imageUrl > div {
        img {
          width: 325px;
        }
      }
    }
  }
}


// iPhone X  -  375 x 812
@media screen and ( max-width: 375px ) {
  .WorksPage__container {
    .Parallax__tag {
      .imageUrl > div {
        img {
          width: 300px;
        }
      }
    }
  }
}










