// 	---------------  H V R - S W E E P - T O - R I G H T
.hvr-sweep-to-right {
  display: inline-block;
  vertical-align: middle;
  // box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;

  -webkit-transform: perspective(1px) translateZ(0);
  				transform: perspective(1px) translateZ(0);
  -webkit-transition-property: color;
  				transition-property: color;
  -webkit-transition-duration: 0.3s;
  				transition-duration: 0.3s;
}
.hvr-sweep-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background: #2098D1;
  background: rgba(255, 255, 255, 0.7);

  -webkit-transform: scaleX(0);
  				transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  				transform-origin: 0 50%;
  -webkit-transition-property: transform;
  				transition-property: transform;
  -webkit-transition-duration: 0.3s;
  				transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  				transition-timing-function: ease-out;
}
.hvr-sweep-to-right:hover, 
.hvr-sweep-to-right:focus, 
.hvr-sweep-to-right:active {
  color: black !important;
  font-weight: 500;
}
.hvr-sweep-to-right:hover:before, .hvr-sweep-to-right:focus:before, .hvr-sweep-to-right:active:before {
  -webkit-transform: scaleX(1);
  				transform: scaleX(1);
}
























// 	-----------------------------  K U K U R I  /  I L I N
/* General link styles */
.link {
	outline: none;
	text-decoration: none;
	position: relative;
	/*font*/-size: 8em;
	line-height: 1;
	// color: #9e9ba4;
	// color: white;
	display: inline-block;
}

/* Kukuri */
.link--kukuri {
	text-transform: uppercase;
	/*font*/-weight: 900;
	overflow: hidden;
	line-height: 0.75;
	// color: #c5c2b8;
	color: white;  //		default : white
}

.link--kukuri:hover {
	// color: #c5c2b8;
	// color: #a3734c;	dark gold rust color
	// color: #cc8800;	ligher gold rust color
	color: white;
}

.link--kukuri::after {
	content: '';
	position: absolute;
	height: 3px;
	width: 100%;
	top: 50%;
	margin-top: 12px;
	right: 2px;
	background: #F9F9F9;
	// background: linear-gradient(90deg, #cc8800 0, #a3734c);
	-webkit-transform: translate3d(-100%,0,0);
	transform: translate3d(-100%,0,0);
	-webkit-transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	-webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	transition-timing-function: cubic-bezier(0.7,0,0.3,1);
}

.link--kukuri:hover::after {
	-webkit-transform: translate3d(110%,0,0);
	transform: translate3d(110%,0,0);
}

.link--kukuri::before {
	content: attr(data-letters);
	position: absolute;
	z-index: 2;
	overflow: hidden;
	// color: #424242;
	color: #a3734c;
	white-space: nowrap;
	width: 0%;
	-webkit-transition: width 0.4s 0.3s;
	transition: width 0.4s 0.3s;
}

.link--kukuri:hover::before {
	width: 100%;
}




/* Ilin */
.link--ilin {
	font-weight: 900;
	text-transform: uppercase;
	line-height: 0.8;
	overflow: hidden;
	color: #adaa88;
}

.link--ilin span {	
	position: relative;
	display: inline-block;
	-webkit-transition: color 0.5s;
	transition: color 0.5s;
}

.link--ilin:hover span:first-of-type {
	color: #fff;
}

.link--ilin:hover span:last-of-type {
	color: #1e1a1b;
}

.link--ilin span::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background: #fff;
	line-height: 0.8;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
}

.link--ilin span:last-of-type::before {
	background: #1e1a1b;
}

.link--ilin:hover span:last-of-type::before,
.link--ilin span:first-of-type::before {
	-webkit-transform: translate3d(0,-150%,0);
	transform: translate3d(0,-150%,0);
}

.link--ilin:hover span:first-of-type::before,
.link--ilin span:last-of-type::before {
	-webkit-transform: translate3d(0,150%,0);
	transform: translate3d(0,150%,0);
}



/* Media Queries */
// @media screen and (max-width: 50em) {
// 	.link--surinami { font-size: 3em; }
// }

// @media screen and (max-width: 42em) {
// 	.link--kukuri { font-size: 3.5em; }
// 	.link--ilin { font-size: 5em; }
// }





