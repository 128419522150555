.stillsPageDetails__wrapper {
	width: 200px;
	height: 25px;
	position: fixed;
	z-index: 10000;
	left: 0;
	bottom: 21px;

	.animate_right {
    width: 100%;
    margin: auto;
    overflow: hidden;
    position: absolute;
    transition: 0.3s;
    z-index: 9999;
    left: 0;

    h2 {
			color: darkslategray;
			font-family: 'Montserrat', sans-serif !important;
			font-size: 15px;
			font-weight: 400;
			letter-spacing: 1px;
			line-height: 21px;
			position: relative;
			left: 43px;

			&:hover {
				cursor: pointer;
			}
    }

    &::after {
	    width: 35px;
	    height: 1px;
	    background: gray;
	    content: '';
	    display: block;
	    position: absolute;
	    top: 11px;
    }

		.EmailMe {
	    width: 23px;
	    height: 23px;
	    align-items: center;
	    display: flex;
	    cursor: pointer;
	    justify-content: center;
	    margin-left: 0;
	    position: absolute;
	    top: 0;
	    left: 43px;
		}
	}

	.pageDetails {
		.closeModal {
	    color: white;
	    font-size: 12px;
	    letter-spacing: 1px;
	    position: absolute;
	    top: 25px;
	    right: 25px;

			&:hover {
				cursor: pointer;
			}
		}

    h4 {
    	color: white;
    	font-family: 'Montserrat', sans-serif !important;
			font-size: 13px;
			font-weight: 400;
			letter-spacing: 1px;
			margin-bottom: 5px;
			text-transform: uppercase;

			&:first-of-type {
				border-bottom: 1px solid silver;
		    padding-bottom: 10px;
		    margin-bottom: 20px;
			}
    }

    ul {
			font-family: 'Montserrat', sans-serif !important;
			font-size: 13px;
			font-weight: 400;
			letter-spacing: 0;
			margin: 0;
			padding: 0 15px 15px;
			-webkit-font-smoothing: antialiased;

			li {
				color: white;
				line-height: 17px;
				// list-style: none;
			}

			a {
				color: white;
				line-height: 25px;
				text-decoration: underline;
			}
    }
	}
}


@media screen and ( max-width: 550px ) {
  .stillsPageDetails__wrapper .animate_right {
    h2 { left: 23px; }
    &::after {
      width: 15px;
    }
  } 
}


// .ReactModal__Body--open {
//   	animation-name: modalFade;
//   	animation-duration: 1s;
// }
// // .modal {
// //   animation-name: modalFade;
// //   animation-duration: .3s;
// // }
// @keyframes modalFade {
//   from {
//   	opacity: 0;
//   	// transform: translateY(-50%);
//   }
//   to {
//   	opacity: 1;
//   	// transform: translateY(0);
//   }
// }


// .closeModal {
// 	position: absolute;
// 	z-index: 19999;

// 	&:hover {
// 		cursor: pointer;
// 	}
// }


