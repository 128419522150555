

.pageInProgress {
  // border: 1px solid red;

  width: 170px;
  color: darkslategray;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 21px auto 0;
  position: absolute;
  text-align: center;
  text-align: left;
  text-transform: uppercase;

  left: 0;
  right: 0;
}


//  LIGHT BOX
.fslightbox-open {
  #HamburgerFixed {
    z-index: 1 !important;
  }
}



.hover_scroll_component {
  margin: auto;
  max-width: 1280px;
  position: absolute;
  right: 0;
  left: 0;

  .animate_delay {
    .mobileLinksWrapper {
      display: none;
    }
    .homeNavBtnsStyle {
      // width: 25%;
      width: 320px;
      height: 563px;
      background: transparent;
      margin: auto;
      opacity: 1;
      overflow: hidden;
      position: absolute;
      transition: all 0.5s ease-in-out;

      top: 0;
      bottom: 0;

      > div:first-of-type {}


      //-------------------------  HOME BTN STYLE
      .homeBtnStyle_container {
        width: 45px;
        background: #fff;
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 9999;

        .animate_right {
          border-right: 1px solid darkslategray;
          height: 100%;

          .page {
            height: 100%;

            .page__inner {
              height: 100%;

              .homeBtnStyle {
                width: 65px;
                height: 40px;
                color: darkslategray;
                letter-spacing: 4px;
                line-height: 38px;
                margin: auto;
                position: fixed;
                position: absolute;
                opacity: 1;
                transform: rotate(-90deg);
                top: 0;
                left: -8px;
                bottom: 0;
              }
            }
          }      
        }
      }


      //-------------------------  LINKS WRAPPER
      .linksWrapper_container {
        height: 100%;
        justify-content: center;
        position: relative;
        transition: all 0.5s ease-in-out;

        // REMOVE to allow linksWrapper_container to AnimateRight
        // transition-delay: 2s;

        right: 0;
        left: 0;

        .animate_right {
          height: 100%;
          margin: auto;
          position: relative;

          right: 0;
          left: 0;

          display: flex;
          align-items: center;
          justify-content: center;

          .page {

            .page__inner {

              p.FilterLink__wrapper {
                height: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                top: 0;
                left: 0;
                z-index: 9999;
                margin: 0;
                justify-content: center;
                align-items: center;
              }

              .FilterLink__wrapper a,
              .FilterLink__wrapper span {
                width: 155px;
                color: darkslategray;
                font-family: 'Montserrat', sans-serif !important;
                font-size: 15px;
                font-weight: 400;
                letter-spacing: 1px;
                margin: 10px 0;
                position: relative;
                text-align: left;
                text-transform: uppercase;
              }
              .FilterLink__wrapper span {
                border-bottom: 1px solid darkslategray;
                padding-bottom: 15px;
              }
            }
          }
        }
      }
    }
  }

  .contactBtnStyles {
    border: 2px solid purple;
    width: auto;
    height: 40px;
    background: none;
    border: none;
    color: darkslategray;
    font-family: 'Montserrat',sans-serif !important;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0 auto;
    outline: none;
    padding: 0;
    position: fixed;
    text-transform: lowercase;
    z-index: 9999;

    left: 45px;
    bottom: 0;

    &:hover {
      cursor: pointer;
    }

    a::after {
      width: 2px;
      height: 20px;
      background: darkslategray;
      content: '';
      display: block;
      margin: auto;
      position: relative;
      top: 5px;
      bottom: 0;
      left: 0;
    }

    .animate_up {
      position: relative;
      z-index: 9998;

      .page {
        .page__inner {
          a {}
        }
      }
    }
  }
}


//  ------------------  masonry-grid.component.jsx
.masonryGridComponent {
  position: absolute;

  .masonry_grid {
    width: auto;
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    position: relative;
    transition: 1s;
    left: 0;
    scroll-behavior: smooth;
    
    .masonry_grid_column {
      background-clip: padding-box;
    }

    // $tile-bg: #cfcfdf;
    .still {
      width: 300px;
      height: 185px;
      animation: .3s ease-in-out both fade-in;
      background-size: cover !important;
      color: transparent;
      display: flex;
      font-size: 18px;
      font-weight: bolder;
      float: left;
      letter-spacing: 3px;
      margin: 2px;
      opacity: 0;
      transition: 0.3s;

      align-items: center;
      justify-content: center;
      text-transform: capitalize;

      h5 {
        font-family: 'Montserrat',sans-serif !important;
        font-weight: 100;
        text-transform: uppercase;
      }

      &:hover {
        cursor: pointer;

        h5 {
          color: white;
          opacity: 1;
          z-index: 1;
        }

        span {
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,0.8);
          content: '';
          display: block;
          position: absolute;
          transition: 0.3s;
          
          top: 0;
          left: 0;
        }
      }
    }
  }
}

// Loop from 1-12.
// @for $i from 1 through 1000 {
//   .still {
//     &:nth-child(#{$i}) {
//       // Delay the animation. Delay increases as items loop.
//       animation-delay: $i * (0.4s) !important;
//     }
//   }
// }

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0.4);
    transition: 0.7s;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    transition: 0.7s;
  }
}


// iPhone 8 Plus  -  1080 x 1920
@media screen and ( max-width: 1080px ) {
  .hover_scroll_component {
    .stillsPageDetails__wrapper {
      top: 55px;
      bottom: unset;
    }
    .animate_delay {
      .mobileLinksWrapper {
        // border: 1px solid blue;
        display: block;
        margin-top: 100px;
        position: relative;
        top: 0;
        z-index: 9999;

        h3 {
          width: calc(100% - 82px);
          border-bottom: 1px solid silver;
          color: red;
          color: darkslategray;
          font-family: 'Montserrat', sans-serif !important;
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 1px;
          line-height: 35px;
          margin: 0 auto;
          position: relative;
          text-align: center;
          text-transform: uppercase;
          left: 2px;

          &:hover {
            cursor: pointer;
          }
        }

        .mobileFilterBtnStyle {
          // border: 1px solid red;
          width: 100%;
          // height: 150px;
          background: rgba(255,255,255,0.9);
          // background: silver;
          overflow: hidden;
          position: absolute;
          transition: 0.3s;

          top: 36px;
          right: 0;
          left: 0;

          .FilterLink__wrapper {
            height: 100%;
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0;
            position: relative;
            z-index: 9999;

            top: 0;
            left: 0;

            > span { display: none; }
            a {
              color: gray;
              font-family: 'Montserrat', sans-serif !important;
              font-size: 13px;
              font-weight: 400;
              letter-spacing: 1px;
              line-height: 25px;
              text-transform: uppercase;
            }
          }
        }        
      }

      .homeNavBtnsStyle {
        border: 2px solid red;
        width: 100%;
        height: 200px;
        bottom: unset;
        display: none;

        .homeBtnStyle_container {
          display: none;
          // .animate_right .page .page__inner .homeBtnStyle {
          //   transform: none;
          // }
        }

        .linksWrapper_container {
          border: 2px solid blue;
          height: 100%;
          z-index: 9999;
          top: 10px;
        }
      }

      .imgs_container {
        .masonryGridComponent .masonry_grid .masonry_grid_column {
          margin: 0 2px;
        }
      }
    }
  }

  .masonryGridComponent {
    top: 25px;

    .masonry_grid {
      .still {
        width: 343px;
        width: 100%;
        height: 205px;
        opacity: 0;
      }
    }
  }
}




@media screen and ( max-width: 800px ) {
  .hover_scroll_component .animate_delay .mobileLinksWrapper h3 {
    width: calc(100% - 49px);
  }
}











