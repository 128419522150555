.backToTop__wrapper {
	// border: 1px solid red;
  width: 100px;
  height: 35px;
  margin: auto;
  overflow: hidden;
  position: fixed;
  z-index: 9999;
  right: 0;
  bottom: 25px;

	.animate_left {

	  &::after {
			width: 35px;
			height: 1px;
			background: gray;
			content: '';
			display: block;
			position: absolute;
			top: 11px;
			right: 0;
	  }
		.ArrowUp {
	    width: 34px;
	    height: 22px;
	    align-items: center;
	    display: flex;
	    cursor: pointer;
	    justify-content: center;
	    position: absolute;
	    top: 0;
	    right: 35px;
		}
	}
}


@media screen and ( max-width: 750px ) {
	// .backToTop__wrapper {
	// 	.animate_left {
	// 		// .ArrowUp { right: 20px; }
	// 		&::after { width: 20px; }
	// 	}		
	// }
}

@media screen and ( max-width: 550px ) {
	.backToTop__wrapper {
		.animate_left {
			&::after { width: 15px; }
			.ArrowUp { right: 15px; }
		}
	}
}

// iPhone X  -  375 x 812
@media screen and ( max-width: 375px ) {
	// .backToTop__wrapper .animate_left {
	// 	.ArrowUp {
	// 	  right: 20px;
	// 	}

	// 	&::after {
	// 	  width: 20px;
	// 	}
	// }
}



