
.card {
  // width: 50%;
  // height: 50%;
  // background: grey;
  border-radius: 5px;
  // box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
  will-change: transform;
  // border: 15px solid white;
  // overflow: hidden;
}

.img {
  width: 100%;
  height: 100%;
  background-image: url(https://drscdn.500px.org/photo/435236/q%3D80_m%3D1500/v2?webp=true&sig=67031bdff6f582f3e027311e2074be452203ab637c0bd21d89128844becf8e40);
  background-size: cover;
  background-position: center center;
}