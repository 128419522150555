/*  N O T E  :  D e l a y i n g   A n i m a t i o n s

  On component mount, delays set on component :;
    - <TransitionRight delayOnMount={ 1200 }>

  On component exit '.page-exit' :
    - transition-delay: 1s;  ----->  WORKS HERE


  E X A M P L E  :
  - this does not work targeting individual components separately.

  .About__Container.page-enter { ... }
  .About__Container.page-enter-active { ... }
  .About__Container.page-enter-done { ... }
*/



//  ----------------------------------  W O R K S  :  P A G E
.page-enter .ContactPage__Wrapper { 
  opacity: 0;
  transition: all 3s ease-in;
  transition-delay: 3s;
}
.page-enter-active .ContactPage__Wrapper { 
  opacity: 1;
  transition: all 3s ease-in;
}
// .page-exit .ContactPage__Wrapper { 
//   opacity: 0;
//   transition: all 0.3s ease-in;
//   transition-delay: 1.8s;
// }


/* USE MIXINS HERE :
   EXAMPLE - https://www.udemy.com/course/complete-react-developer-zero-to-mastery/learn/lecture/15037558#overview
          - 5:20 mark
          - use the focus style from this lesson on portfolio
*/

//  ------------------  C O N T A C T  :  P A G E
.page-exit .ContactPage__Wrapper {
  opacity: 0;
  transition: all 0.3s ease-in;
  transition-delay: 1s;

  // .bkgdPageTitle {
  //   .animate_up {
  //     transition-delay: 3s;
  //   }
  // }

  // ---------------------  UNCOMMENT - to transition each element individually
  /*
  .contact__header {
    h5 {
      opacity: 0;
      transition: all 0.3s ease-in;
      transform: translate( 0px, -100px );
      // transition-delay: 0.3s;
      transition-delay: 1.3s;
    }
    h3 {
      opacity: 0;
      transition: all 0.3s ease-in;
      transform: translate( 0px, -100px );
      // transition-delay: 0.5s;
      transition-delay: 1.3s;
    }
  }

  .ContactImgForm__Wrapper {
    .contactImg__container {
      opacity: 0;
      transition: all 0.3s ease-in;
      transform: translate( 0px, -100px );
      // transition-delay: 1s;
      transition-delay: 1.3s;
      transition-timing-function: cubic-bezier(.43,-0.01,1,.66);
    }
    .contactForm__container {
      opacity: 0;
      transition: all 0.3s ease-in;
      transform: translate( 0px, -100px );
      // transition-delay: 1.2s;
      transition-delay: 1.3s;
      transition-timing-function: cubic-bezier(.43,-0.01,1,.66);
    }
  }
  */
}

















