
//-----------------------------  REACT MODAL
.ReactModal__Overlay--after-open {
  // border: 2px solid red;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.9);
  position: fixed;
  transition: 1s;
  z-index: 999999;
}
.ContactFormModal__Wrapper,
button.closeModal {
  color: white;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 100;
  letter-spacing: 2px;
  outline: none;
  position: absolute;
  text-transform: uppercase;
}
.ContactFormModal__Wrapper {
  width: calc(100% - 100px);
  height: calc(100% - 100px);
  background: #161616;
  display: flex;
  text-transform: uppercase;
  margin: auto;

  align-items: center;
  justify-content: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
button.closeModal {
  background: transparent;
  width: 75px;
  height: 35px;
  border: none;
  top: 25px;
  right: 25px;

  &:hover { cursor: pointer; }
}




/* FORM STYLES */
form#contactForm {
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // grid-gap: 20px;
  display: flex;
  flex-direction: column;

  label {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 2px;
    margin: 0;
    text-transform: uppercase;
  }
  input {
    width: 100%;
    height: 30px;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #cccccc;
    border-radius: 0;
    font-size: 15px;
    letter-spacing: 1px;
    outline: none;
    -webkit-font-smoothing: antialiased;
  }
  .name,
  .email,
  .message {
    margin-bottom: 25px;
  }

  .message {
    textarea {
      width: 100%;
      height: 50px;
      background: transparent;
      border: 0;
      border-bottom: 1px solid #cccccc;
      font-size: 15px;
      letter-spacing: 1px;
      outline: none;
    }            
  }
  .submitBtn.hvr-sweep-to-right {
    border: 1px solid #cccccc;
    width: 150px;
    height: 45px;
    text-align: center;

    &::before {
      background: rgba(27, 27, 27, 1);
    }

    button {
      background: transparent;
      border: none;
      width: 100%;
      height: 100%;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 2px;
      margin: 0;
      outline: none;
      text-transform: uppercase;
      transition: 0.3s;

      &:hover {
        color: white;
        cursor: pointer;
      }
    }
  }
}





/* LARGE SCREENS */
// @media(min-width:700px) {
//   .wrapper {
//     display: grid;
//     grid-template-columns: 1fr 2fr;
//   }

//   .wrapper > *{
//     padding:2em;
//   }

//   .company-info h3, .company-info ul, .brand{
//     text-align: left;
//   }
// }