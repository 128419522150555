main {
  #HamburgerFixed.animate_left {
    // border: 1px solid red;
    width: 95px;
    // width: 100%;
    position: fixed;
    z-index: 10002;

    top: 0;
    right: 0;
  }
}



/*  --------------------------- hamburger.component.jsx  */
.HamburgerBtn__container {
  width: 30px;
  height: 20px;
  color: white;
  cursor: pointer;
  display: flex;
  padding: 0;
  position: relative;
  z-index: 10001;

  align-items: center;
  flex-direction: column;
  justify-content: center;

  top: 25px;
  left: 0;

  h6 {
    color: gray;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    right: -46px;

    .open { color: gray; }
    .closed { color: gray; }

    span {
      &::after {
        /* This value is the OPPOSITE color of our background */
        color: rgb(255, 255, 255); 
        mix-blend-mode: difference;
      }      
    }

  }


  .HamburgerBtn__line {
    height: 2px;
    width: 100%;
    transition: all 0.3s ease;
  }
  .HamburgerBtn__lineTop {
    width: 70%;
    position: absolute;
    top: 1px;
    right: 0;
  }
  .HamburgerBtn__lineMiddle {
    width: 90%;
    position: absolute;
    top: 7px;
    right: 0;
  }
  .HamburgerBtn__lineBottom {
    width: 50%;
    position: absolute;
    top: 13px;
    right: 0;
  }
}



/*  --------------------------- menu.component.jsx  */
// .HamburgerMenu__container {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100%;
//   // display: flex;
//   flex-direction: column;
//   background: black;
//   // opacity: 0.95;
//   color: #fafafa;
//   z-index: 2;
//   transition: opacity 0.3s ease-in-out;
  // transition: height 0s ease;
  // background-image: linear-gradient(black, white);

  // .menuList { 
  //   // border: 2px solid yellow;
  //   width: max-content;
  //   height: 100%;
  //   display: flex;
  //   padding-top: 28px;
  //   position: relative;
  //   // transition: 1s;
  //   // left: -100%;
  //   left: 100px;
  // }
// }



/*  --------------------------- menu-item.component.jsx  */
// .HamburgerMenuItem__container {
//   // border: 1px solid red;
//   width: max-content;
//   opacity: 0;
//   position: relative;

//   animation: 1s appear forwards;
//   transition: 2s;
  
//   .HamburgerMenuItem__menuItem {
//     cursor: pointer;
//     font-family: 'Montserrat', sans-serif !important;
//     font-size: 16px;
//     font-weight: 400;
//     letter-spacing: 1px;
//     margin: 0 10px;
//     padding: 12px 0;
//     position: relative;
//     text-transform: uppercase;

//     // transition: color 0.2s ease-in-out;
//     // animation: 0.5s slideIn forwards;
//   }
// }
// .HamburgerMenuItem__line {
//   width: 90%;
//   height: 1px;
//   background: gray;
//   margin: 0 auto;
//   animation: 0.5s shrink forwards;
// }


/*  --------------------------- KEYFRAMES */
@keyframes appear {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes slideIn {
  0% { transform: translateX(-2%); }
  100% { transform: translateX(0); }
}
@keyframes shrink {
  0% { width: 95%; }
  100% { width: 90%; }
}












//  Mac
@media screen and ( max-width: 1280px ) {

}


// iPhone X  -  1125 x 2436
@media screen and ( max-width: 1125px ) {

}


// iPhone 8 Plus  -  1080 x 1920
@media screen and ( max-width: 1080px ) {
  
}


@media screen and ( max-width: 1000px ) {

}


@media screen and ( max-width: 900px ) {

}


// iPhone 8  -  750 x 1334
@media screen and ( max-width: 750px ) {

}

@media screen and ( max-width: 414px ) {

}







