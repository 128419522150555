.projects__container {
	.jobDescs__wrapper {
		margin: 0 auto;
	  max-width: 650px;

		h4.role {
	    font-family: 'Montserrat', sans-serif;
	    font-size: 17px;
	    font-weight: 500;
	    letter-spacing: 1px;
	    margin: 15px 0;
		}
		.jobDescs {
	    padding: 0 15px;
		}
		.jobdesc {
			font-family: 'Raleway', sans-serif;
			font-size: 14px;
			font-weight: 300;
	    letter-spacing: 1px;
			line-height: 30px;
			position: relative;
		}
	}
}

.Parallax__tag {
	.projects__wrapper:nth-of-type(4) {
		h4 {
			display: none;
		}
	}
}
















